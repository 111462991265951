<template>
  <div class="group-content" v-loading="formLoading">
    <el-form
      size="medium"
      :model="groupForm"
      :rules="rules"
      label-position="right"
      label-width="95px"
      class="small-form"
      ref="groupForm"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">基础信息</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="名称：" prop="name">
            <el-input
              type="text"
              v-model="groupForm.name"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <!--          <el-form-item label="分类：" prop="category_id">-->
          <!--            <el-select v-model="groupForm.category_id" :placeholder="optionsLoading?'分类加载中...':'请选择分类'">-->
          <!--              <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!-- <el-form-item label="排序：" prop="sort">
            <el-input type="number" v-model.number="groupForm.sort" placeholder="请输入序号">
            </el-input>
          </el-form-item> -->
          <el-form-item label="简介：" prop="description">
            <el-input
              type="textarea"
              :rows="8"
              :maxlength="500"
              show-word-limit
              v-model="groupForm.description"
              placeholder="请输入简介信息"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="加入须知：" prop="join_notice">
            <el-input
              type="textarea"
              :rows="8"
              :maxlength="500"
              show-word-limit
              v-model="groupForm.join_notice"
              placeholder="请输入加入须知"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="logo：" prop="icon">
            <image-wall v-model="groupForm.icon" :width="80" :height="80" ratio="160:160">
              <p slot="info" class="img-tips">
                <span class="el-icon-info" />建议尺寸为300*300
              </p>
            </image-wall>
          </el-form-item>
          <el-form-item label="详情页底图：" prop="image">
            <div class="input-item">
              <image-wall v-model="groupForm.image" :width="194" :height="135" ratio="5:4">
                <p slot="info" class="img-tips">
                  <span class="el-icon-info"></span>建议尺寸为1500*1200
                </p>
              </image-wall>
            </div>
          </el-form-item>
          <el-form-item label="群聊二维码：" prop="wx_qrcode">
            <div class="input-item" style="width: 160px">
              <image-wall
                v-model="groupForm.wx_qrcode"
                :width="300"
                :height="300"
                ratio="300:300"
              >
                <p slot="info" class="img-tips">
                  <span class="el-icon-info"></span>建议尺寸为300*300
                </p>
              </image-wall>
            </div>
          </el-form-item>

          <el-form-item label="推荐：" prop="is_recommend">
            <el-radio v-model="groupForm.is_recommend" :label="1"
              >推荐</el-radio
            >
            <el-radio v-model="groupForm.is_recommend" :label="0"
              >不推荐</el-radio
            >
          </el-form-item>
          <el-form-item label="显示：" prop="is_show">
            <el-radio v-model="groupForm.is_show" :label="1">显示</el-radio>
            <el-radio v-model="groupForm.is_show" :label="0">不显示</el-radio>
          </el-form-item>
        </div>
      </div>
      <div class="modular" v-if="$route.name === 'addGroup'">
        <p class="modular-title">成员配置</p>
        <div
          class="modular-content"
          style="margin-left: 11px"
          v-for="(item, index) in membersFormConfig"
          :key="index"
        >
          <el-form-item :label="`${item.name}：`" :prop="item.key">
            <el-button type="primary" @click="handleSelectMember(item.key)"
              >选择会员</el-button
            >
            <el-checkbox-group
              v-show="false"
              v-model="groupForm[item.key]"
            ></el-checkbox-group>
          </el-form-item>
          <div class="member-list" v-if="groupForm[item.key].length">
            <div class="list-top">
              <div class="top-left">
                已添加会员数：{{ groupForm[item.key].length }}
              </div>
              <div class="top-right">
                <el-button
                  type="text"
                  class="clear-member"
                  @click="clearMembers(item.key)"
                  >清空</el-button
                >
              </div>
            </div>
            <div class="list-content">
              <el-tag
                class="list-item"
                :key="item.user_id"
                v-for="(item, i) in groupForm[item.key]"
                closable
                @close="groupForm[item.key].splice(i, 1)"
              >
                {{ item.nickname }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="modular" v-if="$route.name === 'addGroup'">
        <p class="modular-title">相关设置</p>
        <div class="modular-content" style="margin-left: 11px">
          <el-form-item label="加入审核：" prop="require_check">
            <el-radio v-model="groupForm.config.require_check" :label="0"
              >直接通过</el-radio
            >
            <el-radio v-model="groupForm.config.require_check" :label="1"
              >需要审核</el-radio
            >
          </el-form-item>
          <el-form-item label="自动加入小组：" prop="member_auto_join">
            <el-radio v-model="groupForm.config.member_auto_join" :label="1"
              >开启</el-radio
            >

            <el-radio v-model="groupForm.config.member_auto_join" :label="0"
              >关闭</el-radio
            >
            <div class="form-tip">
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span>开启后会员可按组织架构加入对应小组，无需审批</span>
            </div>
          </el-form-item>
          <el-form-item
            label="选择架构："
            prop="config.auto_join_organizations"
            v-if="groupForm.config.member_auto_join"
          >
            <el-cascader
              v-model="groupForm.config.auto_join_organizations"
              :options="selectPositionOptions"
              :props="{
                checkStrictly: true,
                expandTrigger: 'hover',
                label: 'name',
                value: 'id',
                emitPath: false,
                multiple: true,
              }"
              :style="{ width: '100%' }"
              filterable
              @change="handleExportCatalogChange"
              ref="CascaderRef"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="发帖限制：" prop="post_limit">
            <el-radio v-model="groupForm.config.post_limit" :label="0"
              >不限制</el-radio
            >
            <el-radio v-model="groupForm.config.post_limit" :label="1"
              >仅限成员</el-radio
            >
          </el-form-item>
          <el-form-item label="发帖审核：" prop="post_check">
            <el-radio v-model="groupForm.config.post_check" :label="0"
              >先发后审</el-radio
            >
            <el-radio v-model="groupForm.config.post_check" :label="1"
              >先审后发</el-radio
            >
          </el-form-item>
          <el-form-item label="黑名单限制：" prop="black_config">
            <el-checkbox-group v-model="groupForm.config.black_config">
              <el-checkbox :label="1">无法发帖</el-checkbox>
              <el-checkbox :label="2">无法评论</el-checkbox>
              <el-checkbox :label="3">无法申请加入</el-checkbox>
            </el-checkbox-group>
            <div class="form-tip" style="margin-top: 5px">
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span
                >当前设置仅针对单一小组，如用户为该小组黑名单，则无法申请加入该小组或发布评论等，在其他小组操作无影响。</span
              >
            </div>
          </el-form-item>
          <el-form-item
            label-width="110px"
            label="黑名单自动移出："
            prop="black_out_limit"
          >
            <el-input v-model.number="groupForm.config.black_out_limit">
              <i slot="suffix" style="margin-right: 10px">天</i>
            </el-input>
            <div class="form-tip">
              <span
                class="el-icon-info"
                style="font-size: 15px; color: #c0c4cc"
              ></span>
              <span>设置自动移出黑名单的天数，为空，则不自动移出。</span>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <SelectMember
      v-model="openSelectMember"
      :disableMembers="currentSelectedMembers"
      :Members="groupForm[currentSelectMemberType]"
      @changeSelect="handleSelect"
    />
    <FixedActionBar>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('groupForm')"
        :loading="saveLoading"
        >保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
  </div>
</template>
<script>
import { groupDetail, groupSave } from '../../api/group/add-group'
import { getOrganizationsTree } from '../../api/group/group-list'

import { categoryOptions } from '../../api/category-list'
import ImageWall from '../../../common/components/SingleMediaWall.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import SelectMember from '@/modules/common/components/MemberSelector'
import { checkImg } from '@/base/utils/regs'
export default {
  components: {
    ImageWall,
    SelectMember,
    FixedActionBar,
  },
  data() {
    return {
      groupForm: {
        id: this.$route.params.id,
        name: '',
        description: '',
        join_notice: '',
        category_id: '',
        sort: '',
        is_recommend: 1,
        is_show: 1,
        image: '', // 详情页底图
        icon: '', // 分享图标
        wx_qrcode: '',
        leader_id: [],
        sub_leader_id: [],
        admin_id: [],
        config: {
          require_check: 1, //加入审核：0：直接通过，1：需要审核
          post_limit: 0, //发帖限制：0：不限制，1：仅限成员
          post_check: 0, //发帖审核：0：先发后审，1：先审后发
          black_config: [1, 2, 3], //黑名单限制
          black_out_limit: '', //黑名单自动移出
          member_auto_join: 0,
        },
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        'config.auto_join_organizations': [
          { required: true, message: '请选择架构', trigger: 'change' },
        ],
        // leader_id: [
        //   {
        //     required: true,
        //     type: "array",
        //     message: "请选择组长",
        //     trigger: "change",
        //   },
        // ],
        // sub_leader_id: [
        //   {
        //     required: true,
        //     type: "array",
        //     message: "请选择副组长",
        //     trigger: "change",
        //   },
        // ],
        // admin_id: [
        //   {
        //     required: true,
        //     type: "array",
        //     message: "请选择管理员",
        //     trigger: "change",
        //   },
        // ],
        // category_id: [
        //   { required: true, message: "请选择分类", trigger: "change" },
        // ],
        icon: [
          {
            required: true,
            validator: checkImg(this, 'icon', {
              formName: 'groupForm',
            }),
          },
        ],
        image: [
          {
            required: true,
            validator: checkImg(this, 'image', {
              formName: 'groupForm',
            }),
          },
        ],
      },
      membersFormConfig: [
        { name: '组长', key: 'leader_id' },
        { name: '副组长', key: 'sub_leader_id' },
        { name: '管理员', key: 'admin_id' },
      ],
      // 架构层级
      selectPositionOptions: [],
      currentSelectMemberType: '', // 当前选择的小组成员类型
      currentSelectedMembers: [], // 当前选中的所有会员
      categoryOptions: [],
      optionsLoading: false,
      formLoading: false,
      saveLoading: false,
      openSelectMember: false,
    }
  },
  methods: {

    // 关闭架构折叠面板
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    // 获取架构
    getPositionOptions() {
      getOrganizationsTree({ is_all: 1, is_show_none: 0 })
        .then((res) => {
          this.selectPositionOptions = res.data
        })
        .catch((err) => {})
    },
    // 获取分类数据选项
    getCategoryOptions() {
      this.optionsLoading = true
      categoryOptions()
        .then((res) => {
          this.categoryOptions = res.data
          this.optionsLoading = false
        })
        .catch((err) => {
          this.optionsLoading = false
        })
    },
    // 点击选择会员
    handleSelectMember(key) {
      this.currentSelectMemberType = key
      this.currentSelectedMembers = [
        ...this.groupForm.leader_id,
        ...this.groupForm.sub_leader_id,
        ...this.groupForm.admin_id,
      ]
      this.openSelectMember = true
    },
    // 获取选择会员
    handleSelect(e) {
      this.groupForm[this.currentSelectMemberType] = e
    },
    // 清空会员
    clearMembers(key) {
      this.groupForm[key] = []
    },
    // 获取品牌详情
    getGroupDetail() {
      this.formLoading = true
      groupDetail({ id: this.groupForm.id })
        .then((res) => {
          const { data } = res
          this.groupForm = data
          this.formLoading = false
        })
        .catch((err) => {
          this.formLoading = false
        })
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.groupForm }
          this.saveLoading = true
          if (this.$route.name === 'addGroup') {
            this.membersFormConfig.forEach((el) => {
              data[el.key] = data[el.key].map((e) => e.user_id)
            })
          }
          groupSave(data)
            .then((res) => {
              this.$message.success(res.msg)
              if (this.$route.name === 'addGroup') {
                this.$router.push({ name: 'CircleGroupList' })
              } else {
                this.getGroupDetail()
              }
              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: 'CircleGroupList' })
    },
  },
  created() {
    // this.getCategoryOptions();
    this.getPositionOptions()

    if (this.groupForm.id != 0) {
      this.getGroupDetail()
    }
  },
}
</script>

<style lang="scss" scoped>
.group-content {
  min-height: 520px;

  .add-host:hover {
    border-color: #409eff;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .upload-btn:hover {
      border-color: #409eff;
    }
  }
  .info-title {
    font-size: 14px;
    line-height: 30px;
  }

  .img-tips {
    margin-top: 8px;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 15px;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.member-list {
  margin-left: 95px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  .list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #dcdfe6;
    .top-left {
      font-weight: bold;
    }
    .top-right {
      .clear-member {
        padding: 0;
      }
    }
  }
  .list-content {
    min-height: 52px;
    padding: 10px 0px 0 10px;
    .list-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.modular-content + .modular-content {
  margin-top: 22px;
}
</style>
