/*
 * @Author: dongjia
 * @Date: 2022-02-09 15:27:04
 * @LastEditTime: 2022-02-09 15:27:08
 * @LastEditors: aleaner
 * @Description: 会员选择弹窗组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\common\api\member-selector.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取选择会员列表
export const SearchMemberList = (data) => {
  return api({
    url: "/admin/cyc/user/search",
    method: "post",
    data,
    notCancel: true,
  });
};

// 获取所属架构/职务列表
export const positionLevel = (data) => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
  });
};
