import api from "@/base/utils/request";

// 获取品牌详情
export const groupDetail = data => {
  return api({
    url: "/admin/circle/group/detail",
    method: "post",
    data
  });
};

// 保存品牌
export const groupSave = data => {
  return api({
    url: "/admin/circle/group/save",
    method: "post",
    data
  });
};
