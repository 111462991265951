var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-vertical",attrs:{"title":_vm.tenantVersion === 'school' ? '选择校友' : '选择会员',"visible":_vm.openDialog,"width":"70%","show-close":false},on:{"update:visible":function($event){_vm.openDialog=$event},"close":_vm.cancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.openDialog = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")])]},proxy:true}])},[_c('div',{staticClass:"head-filter",style:(_vm.tenantVersion === 'school' ? 'width: 80%' : 'width: 50%')},[(_vm.tenantVersion === 'school')?_c('el-cascader',{attrs:{"options":_vm.CampusAndPeriod.campuses,"clearable":"","props":{
        value: 'id',
        label: 'name',
      },"placeholder":"学段"},model:{value:(_vm.PeriodData),callback:function ($$v) {_vm.PeriodData=$$v},expression:"PeriodData"}}):_vm._e(),(_vm.tenantVersion === 'school')?_c('el-cascader',{attrs:{"options":_vm.CampusAndPeriod.periods,"props":{
        value: 'id',
        label: 'name',
      },"placeholder":"校区","clearable":""},model:{value:(_vm.CampusData),callback:function ($$v) {_vm.CampusData=$$v},expression:"CampusData"}}):_vm._e(),(_vm.tenantVersion === 'school')?_c('el-cascader',{attrs:{"options":_vm.EntranceYears,"props":{
        value: 'value',
        label: 'label',
      },"placeholder":"毕业年份","clearable":""},model:{value:(_vm.EntranceYearsData),callback:function ($$v) {_vm.EntranceYearsData=$$v},expression:"EntranceYearsData"}}):_vm._e(),_c('el-cascader',{attrs:{"options":_vm.OrganizationList,"props":_vm.props,"placeholder":"会内职务","clearable":""},model:{value:(_vm.positionIds),callback:function ($$v) {_vm.positionIds=$$v},expression:"positionIds"}}),_c('el-input',{attrs:{"placeholder":"请输入会员名称"},on:{"change":_vm.handleFilter},model:{value:(_vm.filterForm.keyword),callback:function ($$v) {_vm.$set(_vm.filterForm, "keyword", $$v)},expression:"filterForm.keyword"}},[_c('i',{staticClass:"el-icon-search el-input__icon",attrs:{"slot":"suffix"},slot:"suffix"})])],1),_c('div',{staticClass:"member-list",staticStyle:{"margin-top":"35px"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.memberList,"height":350,"highlight-current-row":!_vm.selectType},on:{"row-click":_vm.handleCurrentChange,"selection-change":_vm.handleSelectionChange}},[(_vm.selectType)?_c('el-table-column',{key:Math.random(),ref:"selection",attrs:{"fixed":"","type":"selection","width":"70","selectable":_vm.selectable}}):_vm._e(),(!_vm.selectType)?_c('el-table-column',{key:Math.random(),attrs:{"fixed":"","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-radio',{attrs:{"label":scope.row,"disabled":_vm.disableMembers.length
                ? _vm.disableMembers.findIndex(
                    function (item) { return item.user_id === scope.row.user_id; }
                  ) !== -1
                : _vm.selectedMembers.findIndex(
                    function (item) { return item.user_id === scope.row.user_id; }
                  ) !== -1},model:{value:(_vm.currentRow),callback:function ($$v) {_vm.currentRow=$$v},expression:"currentRow"}},[_vm._v(" "+_vm._s(' '))])]}}],null,false,3329945732)}):_vm._e(),_vm._l((_vm.thead),function(th,index){return [_c('el-table-column',{key:index,attrs:{"prop":th.prop,"label":th.label,"width":th.width || ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm._f("placeholder")(row[th.prop]))+" ")])]}}],null,true)})]})],2)],1),_c('Pagination',{attrs:{"total":_vm.total,"page":_vm.filterForm.page,"change":_vm.onChangPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }